
<template>
  <div class="file_action_contents">
    <div class="file_action_wrapper">
      <main-action />
      <files />
    </div>

    <prompts></prompts>

    <j-modal-slide-for-service v-model="modalOpen">
      <template>
        <div>
          <search v-if="isSearchOpened" />
        </div>
      </template>
    </j-modal-slide-for-service>

  </div>
</template>
<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from 'vuex'
import Files from '../../../lib/filebrowser/src/views/Files'
import Search from '../../../lib/filebrowser/src/components/Search'
import MainAction from '../../../lib/filebrowser/src/components/Sidebar'
import Prompts from '../../../lib/filebrowser/src/components/prompts/Prompts'

export default {
  name: 'sysenv-file-manager',
  components: {
    Files,
    MainAction,
    Prompts,
    Search
  },
  computed: {
    ...mapState([
      'show'
    ]),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'modalOpened'
    ]),
    modalOpen: {
      get() { return this.modalOpened },
      set(val) { this.setModalOpened(val) }
    },
    isSearchOpened() { return this.show == 'search' }
  },
  watch: {
    modalOpened(val) {
      if(val) this.setModalEnv()
    }
  },
  created() {
    this.initFilebrowser()
  },
  methods: {
    ...mapMutations([ 
      'initFilebrowser' 
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setModalOpened'
    ]),

    setModalEnv() { 
      setTimeout(()=> {
        window.document.documentElement.querySelector('.v-overlay').style.zIndex = 98;
        window.document.documentElement.querySelector('.v-dialog__content').style.zIndex = 99;
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.file_action_contents {
  background-color: #fff;
  padding-right: 1.2rem;
  padding-bottom: 1.2rem;
  min-height: calc(100vh - 25rem)

  .file_action_wrapper {
    display: flex;
    max-width: 150rem;

    div:first-of-type {
      width: 100%;
    }
  }
}
#download {
  max-width: 15em;
  border: solid 1px #ff00ff;
}
</style>